
export class  UserDTORequest {
  userUid?:number;
  username?: string;
  password?: string;
  confirmpassword?: string;
  email?: string;
  fullName?: string;
  dob?: string;  
  isActive?:string;
  className?: string;
  phone?: string;
  address?: string;
}