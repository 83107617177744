import heart from "../../assets/icons/heart.svg"
import user from "../../assets/icons/user.svg"
import cart from "../../assets/icons/cart.svg"
import hero1 from "../../assets/images/hero_img1.jpg"
import book_category1 from "../../assets/images/book_category1.png"
import test1 from "../../assets/images/test1.png"
import book_order from "../../assets/images/book_order.jpg"
import logo from "../../assets/images/logo.avif"
import google from "../../assets/icons/google.svg";
import twitter from "../../assets/icons/twitter.svg";
import book_form from "../../assets/images/book_form.jpg"
import color1 from "../../assets/icons/color1.svg";
import color2 from "../../assets/icons/color2.svg";
import color3 from "../../assets/icons/color3.svg";
import color4 from "../../assets/icons/color4.svg";
import color5 from "../../assets/icons/color5.svg";
import color6 from "../../assets/icons/color6.svg";
import color7 from "../../assets/icons/color7.svg";
import color8 from "../../assets/icons/color8.svg";
import color9 from "../../assets/icons/color9.svg";
import color10 from "../../assets/icons/color10.svg";
import color11 from "../../assets/icons/color11.svg";
import color12 from "../../assets/icons/color12.svg";
import play_icon from "../../assets/icons/play_icon.svg";
import book_menu from "../../assets/icons/book_menu.svg";


export const staticImages = {
    heart,
    user,
    cart,
    hero1,
    book_category1,
    test1,
    book_order,
    logo,
    google,
    twitter,
    book_form,
    color1,
    color2,
    color3,
    color4,
    color5,
    color6,
    color7,
    color8,
    color9,
    color10,
    color11,
    color12,
    play_icon,
    book_menu
  };
  