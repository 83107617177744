export class  UserDetail {
  userUid?:number;
  username?: string;
  email?: string;
  fullName?: string;
  dob?: string;  
  isActive?:string;
  className?: string;
  phone?: string;
  address?: string;
  cre_dt?:string;
  avatar?:string;
}